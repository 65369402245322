





























import {computed, defineComponent, onMounted, PropType, ref} from "@nuxtjs/composition-api";
import { Product } from '~/modules/catalog/product/types';
import { useUiState, useUser } from "~/composables";
import {useAddToCart} from "~/helpers/cart/addToCart";
import {useConfigStore} from "~/stores/config";
import { getReservationFee, getInstallmentCalculation } from '~/modules/catalog/product/getters/productGetters';

export default defineComponent({
  name: 'FinanceOnProductDetail',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    cart: {
      type: Object,
      required: false
    },
    financingBtnCheck: {
      type: Boolean,
      required: false
    },
    isCartActiveBox: {
      type: Boolean,
      required: false
    }
  },
  setup(props, {emit}) {
    const {toggleLoginModal} = useUiState();
    const {isAuthenticated} = useUser();
    const {addItemToCart} = useAddToCart();
    const isActiveBox = ref(false);
    const {storeConfig} = useConfigStore()
    const numberOfInstallments = ref(storeConfig.no_of_installments)
    const addProductForFinancing = (product) => {
      if (!isAuthenticated.value) {
        toggleLoginModal();
        return
      }

      addItemToCart({product: product, quantity: 1, custom_option: { reserve_finance: 1 }});
    }
    const activeBox = () => {
      isActiveBox.value = true;
      emit('isActive', isActiveBox);
    }

    const price = computed(() => props?.product?.price_range?.minimum_price?.final_price?.value)
    const alreadyAdded = computed(() => !!props.cart?.items?.some((item) => item.product.sku === props.product.sku))

    return {
      numberOfInstallments,
      alreadyAdded,
      price,
      addProductForFinancing,
      activeBox,
      isActiveBox,
      getReservationFee,
      getInstallmentCalculation
    }
  }
})
